require('./bootstrap');
require('owl.carousel');
require('jquery.easing');
require('jquery-validation');
require('default-passive-events');
// window.pdfjsLib = require('pdfjs-dist');
// pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

import lazyload from '@area17/a17-lazyload';
import lazyframe from "lazyframe";
import smoothscroll from 'smoothscroll-polyfill';
// import "magnific-popup";
// import videojs from 'video.js';
// import 'videojs-youtube';

import Plyr from 'plyr';
// import {getLCP, getFID, getCLS} from 'web-vitals/base';
// import WaveSurfer from 'wavesurfer.js';
//
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/sw.js')
// }
(function ($) {
    "use strict";

    // document.addEventListener('DOMContentLoaded', function () {
    //     var options = {
    //         pageUpdatedEventName: 'page:updated', // how your app tells the rest of the app an update happened
    //         elements: 'img[data-src], img[data-srcset], s`ource[data-srcset], iframe[data-src], video[data-src], [data-lazyload]', // maybe you just want images?
    //         rootMargin: '0px', // IntersectionObserver option
    //         threshold: 0, // IntersectionObserver option
    //         maxFrameCount: 10, // 60fps / 10 = 6 times a second
    //         forceAutoplayVideoPlay: true // force autoplay of autoplay videos
    //     };
    //     lazyload(options);
    // });
    lazyload();
    window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');

        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if ($('input[name="tax"]')) {
                    $('input[name^="tax_"]').prop("required", $('input[name="tax"]').is(":checked"));
                }

                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    form.querySelector('button[type="submit"]').disabled;
                    // add spinner to button
                    form.querySelector('button[type="submit"]').innerHTML = '<i class="fa fa-spinner fa-spin"></i> Loading';
                }

                form.classList.add('was-validated');
            }, false);
        });

        if ($('#preloader').length) {
            $('#preloader').delay(100).fadeOut('slow', function () {
                $(this).remove();
            });
        }

        // window.pdfjsLib = pdfjsLib;
        // Initiate the wowjs animation library
        window.wow.init();

        // let elements = document.querySelectorAll(".p_player");
        // lazyframe(elements);
        const players = Array.from(document.querySelectorAll('.p_player')).map(player => new Plyr(player, {
            // 'settings': ['speed', 'loop'],
            controls: ['play','progress','mute', 'fullscreen' ],
            'youtube': {
                'rel': 0,
            },
            'caption': {
                active: true,
                language: 'auto',
                update: false
            }
        }));
        // const player = new Plyr('.video_player', {
        // 	'settings': ['captions', 'speed', 'quality', 'loop'],
        // 	'caption': {
        // 		active: true,
        // 		language: 'auto',
        // 		update: false
        // 	}
        // });

        // const wavesurfer = WaveSurfer.create({
        //     container: '#waveform',
        //     waveColor: 'violet',
        //     progressColor: 'purple'
        // });

        // Expose player so it can be used from the console
        window.player = players;
        $('.accordion .collapse').collapse();
        // Add minus icon for collapse element which is open by default
        $(".collapse.show").each(function () {
            $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
        });

        // Toggle plus minus icon on show hide of collapse element
        $(".collapse").on('show.bs.collapse', function () {
            $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
        }).on('hide.bs.collapse', function () {
            $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
        });
        $('.carousel').carousel({
            interval: 10000
        })
    }, false);

    // Header scroll class
    // $(window).scroll(function() {
    //   if ($(this).scrollTop() > 100) {
    //     $('.nav_menu').addClass('header-scrolled');
    //     $('.subNav').addClass('bg-white');
    //   } else {
    //     $('.nav_menu').removeClass('header-scrolled');
    //     $('.subNav').removeClass('bg-white');
    //   }
    // });

    if ($(window).scrollTop() > 100) {
        $('.nav_menu').addClass('header-scrolled');
        $('.subNav').addClass('bg-white');
    }

    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.nav_menu').addClass('header-scrolled');
            $('.subNav').addClass('bg-white');
            $('.back-to-top').removeClass("backOutUp").fadeIn('slow');
        } else {
            $('.nav_menu').removeClass('header-scrolled');
            $('.subNav').removeClass('bg-white');
            $('.back-to-top').addClass("backOutUp").fadeOut('slow');
        }
    });
    $('.back-to-top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1500, 'easeInOutExpo');
        return false;
    });

    if (document.querySelector('.custom-file-input')) {
        document.querySelector('.custom-file-input').addEventListener('change', function (e) {
            var fileName = document.getElementById("file").files[0].name;
            var nextSibling = e.target.nextElementSibling
            nextSibling.innerText = fileName
        })
    }

    $('input[name="tax"]').click(function () {
        $('input[name^="tax_"]').prop("required", $('input[name="tax"]').is(":checked"));
    });

    $(document).ready(function () {
        // $(document).on('shown.bs.modal', function (e) {
        //    $(e.target, '[autofocus]').focus()
        //  })

        $('.modal').on('shown.bs.modal', function () {
            $(this).find('[autofocus]').focus();
        });

        if (document.querySelector(".scrollTo")) {
            $('html, body').animate({
                scrollTop: $('.scrollTo').offset().top
            }, 1500, 'easeInOutExpo');
        }
    });


    $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                // Does a scroll target exist?
                var position = 55;
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();

                    var hash = target.attr('id');
                    var form_tuvan = "#dang-ky-tu-van #consultion-register";

                    if (form_tuvan.includes(hash)) {
                        position = 80;
                    }

                    $('html, body').animate({
                        scrollTop: target.offset().top - position
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        }
                        ;
                    });
                }
            }
        });

    // if ($('.magnificPopupVideo').length) {
    //     $('.magnificPopupVideo').magnificPopup({
    //         disableOn: 700,
    //         type: 'iframe',
    //         mainClass: 'mfp-fade',
    //         removalDelay: 160,
    //         preloader: false,
    //
    //         fixedContentPos: false
    //     });
    // }

    var owl = $('.partner_logo');
    if (owl.length) {
        owl.owlCarousel({
            items: 5,
            loop: true,
            margin: 10,
            autoplay: true,
            autowidth: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true
        });
        $('.play').on('click', function () {
            owl.trigger('play.owl.autoplay', [2000])
        })
        $('.stop').on('click', function () {
            owl.trigger('stop.owl.autoplay')
        })
    }


})(jQuery);
